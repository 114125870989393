.tailwind-reset {
    all: initial;
    border-style: none;
}

@keyframes stardust {
    0%,
    100% {
        opacity: 0;
        transform: scale(0.5);
    }
    50% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes natureFadeIn {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.fill-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center;
}

.image-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.logo-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

.logo {
    max-width: 80%;
    max-height: 80%;
    border-radius: 45px;
    transition: opacity 1s ease-in-out;
}

.logo-text {
    margin-top: 10px;
    font-size: 24px;
    color: #0b444f;
    font-weight: bold;
    text-align: center;
}

.stardust {
    position: absolute;
    top: 65%;
    left: 65%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.stardust img {
    width: 100px;
    height: 100px;
    animation: stardust 2s infinite;
}

.nature-fade-in {
    animation: natureFadeIn 2s forwards;
    opacity: 1;
}

.background-svg {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.background-svg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 368px) {
    .background-svg img {
        /* width: auto; */
        height: 100vh;
    }
}

.myname {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the SVG covers the area while maintaining aspect ratio */
}

.container.fade-in .logo-container {
    opacity: 1;
    transition: opacity 2s ease-in-out;
}

.welcome-text {
    opacity: 0;
    animation: fadeIn 2s forwards; /* Fades in the welcome text */
    animation-delay: 1s; /* Delay to start after the nature background */
    position: absolute;
    top: 10%;
    left: 25%;
    width: 80%;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.swipe-up-animation {
    opacity: 0;
    animation:
        fadeInOut 3s infinite,
        floatUpDown 3s infinite;
    /* margin-top: 20px; */
    position: absolute;
    top: 50%;
    left: 40%;
    width: 100%;
}

@keyframes fadeInOut {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes floatUpDown {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8vh);
    }
}
.start-container {
    height: 200vh; /* Make the container taller to allow scrolling */
    position: relative;
    overflow: hidden;
}

.sun {
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-out;
}

/* .second-container { */
/* display: flex; */
/* flex-direction: column; */
/* position: absolute;
    top: 100vh;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease-in-out; */
/* } */
.second-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px; /* Optional: Add some padding */
    min-height: 100vh; /* Ensure the container takes full height */
    box-sizing: border-box; /* Ensure padding is included in height */
}

.second-container .cluster {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5vh;
}

@media (min-width: 368px) {
    .second-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.second-container img {
    max-width: 100%;
    height: auto;
}

.cluster {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

@media (min-width: 368px) {
    .cluster {
        flex-direction: row;
        justify-content: center;
    }
}

.glowing-button,
.version-num {
    margin-top: auto;
    align-self: center;
}

.bottom-cluster {
    position: fixed; /* Fix it to the viewport */
    bottom: 0; /* Align to the bottom */
    left: 0;
    right: 0;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    padding: 20px; /* Add padding if needed */
    background-color: #fff; /* Optional: Background color */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Optional: Shadow for separation */
    z-index: 1000; /* Ensure it stays above other elements */
}

.version-text {
    position: absolute;
    bottom: 10px; /* Adjust as needed for spacing */
    right: 10px; /* Adjust as needed for spacing */
    font-size: 10px; /* Tiny font size */
    color: #666; /* Optional: Font color */
}
.i-am-image-container {
    width: 256px;
    height: 256px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensures any overflow is hidden */
    background-color: #f0f0f0; /* Optional: background color for visibility */
}

.i-am-image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensures the image maintains its aspect ratio */
}
